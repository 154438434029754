import React, { ReactElement, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import { groupBy } from 'lodash';
import { Heading } from '../../../UI';
import { catalogueCards, CatalogueCategories } from '../cards';

import { DictionaryCard } from '../components/DictionaryCard';
import { ModalForm, useModal } from '../components/Modal';
import { useWidth } from '../hooks';
import { DictionaryCardSlider } from '../components/DictionaryCardSlider';

const CardsWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 370px);
    gap: 21px;
    margin-bottom: -16px;

    ${mediaQueries.m} {
      grid-template-columns: repeat(1, 100%);
    }
  `,
);

function CatalogueSection(): ReactElement {
  const isPhone = useWidth();

  const [modalItemName, setModalItemName] = useState('');
  const { isVisibleModal, switchModal } = useModal();

  const handleShowModal = useCallback(
    (itemName: string) => {
      setModalItemName(itemName);
      switchModal();
    },
    [switchModal],
  );

  const standardizedInformationSliderRef = useRef<any>();
  const specializedCategoriesSliderRef = useRef<any>();
  const productCataloguesSliderRef = useRef<any>();

  const cards = groupBy(catalogueCards, 'section');
  const standardizedInformation =
    cards[CatalogueCategories.StandardizedInformation];
  const specializedCatalogues =
    cards[CatalogueCategories.SpecializedCatalogues];
  const productCatalogues = cards[CatalogueCategories.ProductCatalogues];

  return (
    <div>
      <Heading as='h3' fontSize='20px' mt={34} mb={31}>
        Каталоги
      </Heading>
      {isPhone ? (
        <>
          <DictionaryCardSlider
            heading='Стандартизированная информация'
            cards={standardizedInformation}
            sliderRef={standardizedInformationSliderRef}
          />
          <DictionaryCardSlider
            heading='Специализированные каталоги'
            cards={specializedCatalogues}
            sliderRef={specializedCategoriesSliderRef}
            onShowModal={handleShowModal}
          />
          <DictionaryCardSlider
            mb='0px'
            heading='Каталоги товаров'
            cards={productCatalogues}
            sliderRef={productCataloguesSliderRef}
          />
        </>
      ) : (
        <CardsWrapper>
          {catalogueCards.map((props, index) => (
            <DictionaryCard
              {...props}
              key={props.id}
              index={index}
              onShowModal={handleShowModal}
            />
          ))}
        </CardsWrapper>
      )}
      <ModalForm
        text={modalItemName}
        onClose={switchModal}
        isVisible={isVisibleModal}
      />
    </div>
  );
}

export { CatalogueSection };
