import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as ArrowNextIcon } from '../../../../../public/images/nomenclature/slider/button.svg';
import sliderBg from '../../../../../public/images/nomenclature/slider.png';

export const MoreThen = styled.div`
  display: inline-block;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: rgba(184, 212, 255, 0.4);
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #1c1f22;
  transition: background-color 0.4s ease-in-out;
`;

export const SlideText = styled.div(
  ({ theme: { mediaQueries, fontSizes } }) => css`
    max-width: 115px;
    margin: 0 auto;
    font-weight: 500;
    font-size: ${fontSizes[2]}px;
    line-height: 1.4;
    white-space: break-spaces;
    transition: all 0.4s ease-in-out;

    ${mediaQueries.m} {
      display: block;
      width: auto;
      font-weight: normal;
    }
  `,
);

export const ImageWrapper = styled.div`
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;

  > img {
    transition: all 0.4s ease-in-out;
    width: 45%;
  }
`;

export const ArrowPrevIcon = styled(ArrowNextIcon)`
  transform: rotate(180deg);
`;

export const SliderControls = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    > svg {
      position: absolute;
      top: 127px;
      cursor: pointer;
      z-index: 200;

      ${mediaQueries.m} {
        display: none;
      }
    }

    > svg:first-of-type {
      left: -17px;
    }

    > svg:last-of-type {
      right: -17px;
    }
  `,
);

export const Slide = styled.div(
  ({ theme: { colors, mediaQueries } }) => css`
    flex-direction: column;
    width: 168px;
    height: 196px;
    background: #eeeff3;
    border-radius: 16px;
    border: 1px solid #d1d6dc;
    transition: all 0.4s ease-in-out;
    text-align: center;
    cursor: pointer;

    ${mediaQueries.m} {
      margin: 0;
      padding: 0;
      width: inherit;
      height: inherit;
      flex-direction: column-reverse;
      background: ${colors.white};
    }
  `,
);

const showAnimation = keyframes`
 0% { opacity: 0; }
 70% { opacity: 0; }
 100% { opacity: 1; }
`;

export const SliderWrapper = styled.div(
  ({ theme: { colors, mediaQueries } }) => css`
    position: relative;
    width: 466px;
    height: 520px;
    padding-top: 23px;
    background: url('${sliderBg}') no-repeat;
    background-size: contain;
    animation: ${showAnimation} 1s forwards;

    img {
      display: inline;
    }

    ${mediaQueries.m} {
      margin: 0 0 34px;
    }

    .BrainhubCarousel {
      .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
        overflow: visible;
        padding: 46px 0;
      }

      &Item {
        transition: all 0.4s ease-in-out;
        //opacity: 0;

        &.BrainhubCarouselItem--active {
          transform: scale(1.6);
          z-index: 500;

          ${Slide} {
            background-color: ${colors.white};
            height: 180px;
          }

          ${SlideText} {
            padding-top: 4px;
            font-weight: 600;
            font-size: 11px;
          }

          ${ImageWrapper} > img {
            width: 67%;
          }
        }
      }
    }
  `,
);

export { ArrowNextIcon };
