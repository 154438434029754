export const DOMAIN = 'https://istock.info';

export const SEARCH_MODAL_LINK: string = `${DOMAIN}/search/joint`;

export const SEARCH_LINK: string = `${DOMAIN}/search/standard`;

export const GUIDES_LINK: string = `${DOMAIN}/information/dictionaries/standards?sections[]=all`;

export const REGISTRATION_LINK: string = `${DOMAIN}/registration`;

export const AUTH_LINK: string = `${DOMAIN}/auth`;

export const COMPANIES_LINK: string = `${DOMAIN}/dictionaries/companies`;

export const NTD_LINK: string = `${DOMAIN}/dictionaries/standards/NTD?sections%5B%5D=%D0%91%D0%BE%D0%BB%D1%82`;

export const ADVERTISING_LINK: string = `${DOMAIN}/information/advertising`;

export const SECTION = {
  DETAIL_SEARCH: '/api/issue-sections/landing-joint-search',
  FOR_ENGINEER: '/api/issue-sections/landing-constructor',
  FOR_PURVEYOR: '/api/issue-sections/landing-producer',
  FOR_NOMENCLATURE: '/api/issue-sections/landing-nomenclature',
};

export const MESSAGE_TITLE = {
  DETAIL_SEARCH: 'Обращение с лендинга поиска по соединениям',
  FOR_ENGINEER: 'Обращение c лендинга конструкторов',
  FOR_PURVEYOR: 'Обращение с лендинга производителей',
  FOR_NOMENCLATURE: 'Обращение с лендинга номенклатуры',
};
