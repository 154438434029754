import React, { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { banners } from '../cards';

const hideAnimation = keyframes`
 0% { opacity: 1; }
 100% { opacity: 0; display: none }
`;

const BannersWrapper = styled.div(
  () => css`
    width: 220px;
    position: absolute;
    right: 34px;
    padding-top: 80px;

    @media screen and (max-width: 1774px) {
      animation: ${hideAnimation} 1s forwards;
      display: none;
    }
  `,
);

const BannerBlocks = banners.map(({ banner, link }, index) => (
  <a href={link} target='_blank' rel='noreferrer' key={index}>
    <img src={banner} alt='' />
  </a>
));

function BannersSection(): ReactElement {
  return <BannersWrapper>{BannerBlocks}</BannersWrapper>;
}

export { BannersSection };
