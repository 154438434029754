import React, { ReactElement, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../UI';
import { DictionaryCard } from '../components/DictionaryCard';
import { ModalForm, useModal } from '../components/Modal';

import { dictionaryCards } from '../cards';
import { useWidth } from '../hooks';
import { DictionaryCardSlider } from '../components/DictionaryCardSlider';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 370px);
  gap: 24px;
  margin-bottom: 24px;
  margin-top: 41px;
`;

const LittleCards = () => {
  const sliderRef = useRef<any>();
  return (
    <DictionaryCardSlider
      mb='0px'
      pb={18}
      cards={dictionaryCards}
      sliderRef={sliderRef}
    />
  );
};

function DictionariesSection(): ReactElement {
  const [modalItemName, setModalItemName] = useState('');
  const { isVisibleModal, switchModal } = useModal();
  const isPhone = useWidth();

  const handleShowModal = useCallback(
    (itemName: string) => {
      setModalItemName(itemName);
      switchModal();
    },
    [switchModal],
  );

  const Cards = () => {
    return (
      <Wrapper>
        {dictionaryCards.map((props, index) => (
          <DictionaryCard
            {...props}
            key={props.id}
            index={index}
            onShowModal={handleShowModal}
          />
        ))}
      </Wrapper>
    );
  };

  const Component = isPhone ? LittleCards : Cards;

  return (
    <div>
      <Heading as='h3' mb={2} fontSize='20px'>
        Справочники
      </Heading>
      <Component />
      <ModalForm
        text={modalItemName}
        onClose={switchModal}
        isVisible={isVisibleModal}
      />
    </div>
  );
}

export { DictionariesSection };
