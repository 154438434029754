import React, { FC, MouseEvent, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { Heading } from '../../../UI';
import linkHref from '../../../../public/icons/link-next.svg';
import { MODAL_ACTION } from '../constants';
import { useWidth } from '../hooks';
import { InfoButton } from '../../../UI/Button';

const cardWidth = {
  width: '370px',
  height: '370px',
};

const DEFAULT_ZINDEX = '100';
const HOVER_ZINDEX = '110';
const MAX_ZINDEX = '120';

const CardWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: relative;
    ${cardWidth};
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;

    ${mediaQueries.xs} {
      width: 328px;
    }
  `,
);

const CardAnchor = styled.div`
  position: absolute;
  top: -80px;
  height: 0;
  visibility: hidden;
`;

const Card = styled.div<{ isExpandable: boolean; expandTo: 'right' | 'left' }>(
  ({ isExpandable, expandTo, theme: { mediaQueries } }) => css`
    box-sizing: border-box;
    padding: 16px 16px 0;
    position: absolute;
    top: 0;
    left: 0;
    ${cardWidth};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #bfd5ff;
    border-radius: 8px;
    z-index: ${DEFAULT_ZINDEX};
    transition: width 0.6s ease-in-out, box-shadow 0.6s ease-in-out,
      border 0.6s ease-in-out, left 0.6s ease-in-out,
      z-index 0.6s ease-in-out 0.6s;

    ${isExpandable &&
    css`
      &:hover {
        width: 764px;
        box-shadow: 0 0 3px rgba(62, 67, 104, 0.08),
          0 3px 6px rgba(62, 67, 104, 0.1);
        border: 1px solid white;
        z-index: ${HOVER_ZINDEX};
        transition: width 0.6s ease-in-out, box-shadow 0.6s ease-in-out,
          border 0.6s ease-in-out, left 0.6s ease-in-out,
          z-index 0s ease-in-out 0s;

        ${expandTo === 'left' && 'left: -394px;'}
      }
    `}

    ${mediaQueries.xs} {
      width: 328px;
    }
  `,
);

const CardHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 16px;
  gap: 16px;

  height: 95px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const CardIcon = styled.div<{ icon: string }>(
  ({ icon }) => css`
    min-width: 80px;
    min-height: 80px;
    background: url('${icon}');
  `,
);

const InsideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
`;

const BodyWrapper = styled(InsideWrapper)`
  height: 194px;
  border: 1px solid #d1d6dc;
  border-width: 1px 0;
  padding: 24px 8px;
`;

const Footer = styled(InsideWrapper)<{ isExpandingToRight: boolean }>(
  ({ isExpandingToRight, theme: { mediaQueries } }) => css`
    width: 337px;
    height: 74px;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #1c1f22;

    ${isExpandingToRight &&
    css`
      width: 100%;
      transition: all 0.6s ease-in-out;
    `}

    ${mediaQueries.xs} {
      width: 100%;
      font-size: 11px;
    }

    > a {
      display: flex;
      height: 20px;
      align-items: center;
      cursor: pointer;

      &:after {
        content: ' ';
        display: inline-block;
        width: 14px;
        height: 12px;
        margin-left: 6px;
        background: url('${linkHref}') no-repeat right center;
      }
    }
  `,
);

const InnerCard = styled.div<{ gap?: number }>(
  ({ gap = 18, theme: { mediaQueries } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${gap}px;
    width: 370px;

    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 1;

    ${mediaQueries.xs} {
      width: 328px;
    }
  `,
);

const HeadingLine = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 150%;

  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #1c1f22;
`;

const CardHeaderWrapper = styled.a(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    min-width: 370px;
    cursor: pointer;
    color: #1c1f22;

    ${mediaQueries.xs} {
      min-width: 328px;
      font-size: 28px;
    }
  `,
);

const Link = styled.a<{
  isPhone: boolean;
}>(
  ({ isPhone }) => css`
    font-size: ${isPhone ? '14' : '16'}px;
    color: #494f54;
    cursor: pointer;
  `,
);

const Manufacturer = styled.a`
  font-size: 14px;
  color: #494f54;
`;

const IconWrapper = styled.div<{ icon: string }>(
  ({ icon }) => css`
    min-width: 34px;
    min-height: 34px;
    background: url('${icon}') no-repeat center;
    background-size: contain;
    margin-right: 8px;
  `,
);

const ManufacturerWrapper = styled.a`
  display: flex;
  flex-direction: row;
  height: 42px;
  align-items: center;
`;

const DescriptionWrapper = styled.div`
  font-size: 14px;
  padding: 8px;
  box-sizing: border-box;
  max-height: 80px;
  overflow: hidden;
  min-width: 288px;
  width: 288px;
`;

interface ICardsProps {
  id: string;
  title: string;
  headerLink?: string;
  footerLink: string;
  icon: string;
  links: ILinkProps[];
  description?: string;
  footer: string;
  index: number;
  manufacturers?: IManufacturerProps[];
  onShowModal: (itemName: string) => void;
  isPhone?: boolean;
}

interface ILinkProps {
  to: string;
  title: string;
}

interface IManufacturerProps {
  to: string;
  title: string;
  icon?: string;
}

const Links: FC<{
  links: ILinkProps[];
  showModal: (itemName: string) => void;
}> = ({ links, showModal }) => {
  const isPhone = useWidth();

  return (
    <InnerCard gap={isPhone ? 21 : 18}>
      {links.map(({ to, title }) => {
        const notModal = to !== MODAL_ACTION;

        const handleShowModal = (): void => {
          if (showModal) {
            showModal(title);
          }
        };
        return (
          <Link
            isPhone={isPhone}
            key={title}
            href={notModal ? to : undefined}
            target={notModal ? '_blank' : '_self'}
            onClick={notModal ? undefined : handleShowModal}
          >
            {title}
          </Link>
        );
      })}
    </InnerCard>
  );
};

const Manufacturers: FC<{ manufacturers: IManufacturerProps[] }> = ({
  manufacturers,
}) => {
  return (
    <InnerCard gap={11}>
      <HeadingLine>Производители</HeadingLine>
      {manufacturers.map(({ to, title, icon }) => (
        <ManufacturerWrapper href={to} target='_blank' key={title}>
          {icon && <IconWrapper icon={icon} />}
          <Manufacturer>{title}</Manufacturer>
        </ManufacturerWrapper>
      ))}
    </InnerCard>
  );
};

// useRef для хранения не использовать - не отрабатывает вовремя
let effectorIndex = 10;

function DictionaryCard({
  id,
  title,
  icon,
  headerLink,
  footerLink,
  links,
  manufacturers,
  description,
  footer,
  index,
  onShowModal,
  isPhone = false,
}: ICardsProps): ReactElement {
  const handleShowModal = () => {
    if (onShowModal) {
      onShowModal(title);
    }
  };

  const removeZIndex = ({ currentTarget }: MouseEvent<HTMLDivElement>) => {
    setTimeout(() => {
      currentTarget.style.removeProperty('z-index');
    }, 0);
  };

  const addZIndex = ({ currentTarget }: MouseEvent<HTMLDivElement>) => {
    if (currentTarget.style.zIndex) {
      return;
    }

    effectorIndex = 10;
    currentTarget.style.setProperty(
      'z-index',
      String(Number(MAX_ZINDEX) + effectorIndex),
    );
  };

  return (
    <CardWrapper>
      <CardAnchor id={id} />
      <Card
        isExpandable={!!description && !isPhone}
        expandTo={(index + 1) % 3 === 0 ? 'left' : 'right'}
        // повышение на ~1сек z-index активной карточки над потерявшими hover и закрывающимися
        onMouseEnter={addZIndex}
        onMouseLeave={removeZIndex}
      >
        <CardHeader>
          <InsideWrapper>
            <CardHeaderWrapper
              href={headerLink}
              onClick={() => {
                if (!headerLink) {
                  handleShowModal();
                }
              }}
              target='_blank'
            >
              <CardIcon icon={icon} />
              <Heading
                as='h5'
                fontWeight={500}
                style={{ maxWidth: '250px', fontSize: '18px' }}
              >
                {title}
              </Heading>
            </CardHeaderWrapper>
            <DescriptionWrapper>{description}</DescriptionWrapper>
          </InsideWrapper>
        </CardHeader>
        <BodyWrapper>
          <Links links={links} showModal={onShowModal} />
          {manufacturers && <Manufacturers manufacturers={manufacturers} />}
        </BodyWrapper>
        <Footer isExpandingToRight={!!description && (index + 1) % 3 === 0}>
          <div>{footer}</div>
          <InfoButton width={100}>
            <a
              href={footerLink !== MODAL_ACTION ? footerLink : undefined}
              onClick={
                footerLink !== MODAL_ACTION ? undefined : handleShowModal
              }
              target='_blank'
              rel='noreferrer'
            >
              Перейти
            </a>
            <img src={linkHref} alt='arrow' />
          </InfoButton>
        </Footer>
      </Card>
    </CardWrapper>
  );
}

export { DictionaryCard };
