import React, { Suspense } from 'react';
import styled, { css } from 'styled-components';
import { Heading, Text } from '../../UI';
import { ReactComponent as PhoneIcon } from '../../../public/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../../public/icons/email.svg';
import { ContactsForm } from './ContactsForm';
import { IContactFormProps } from './types';

const ContactsWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: grid;
    grid-template-columns: 1fr 57%;
    grid-column-gap: 40px;

    ${mediaQueries.s} {
      display: block;
    }
  `,
);

const ContactItem = styled.div<{ underlined?: boolean }>(
  ({ theme: { colors, mediaQueries }, underlined }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    line-height: 1.6;

    svg {
      margin-right: 8px;
    }

    ${underlined &&
    css`
      a {
        color: ${colors.text.primary};
        border-bottom: 1px dashed ${colors.text.disabled};
      }
    `}

    ${mediaQueries.s} {
      margin-bottom: 8px;
    }
  `,
);

function ContactsSection<T = {}>({
  id,
  ...formProps
}: IContactFormProps<T> & ISection): JSX.Element {
  return (
    <Suspense fallback='Loading...'>
      <div id={id}>
        <Heading as='h2' mb={[16, 16, 32]}>
          Контакты
        </Heading>
        <ContactsWrapper>
          <div>
            <Text mt={16} mb={[16, 16, 28]}>
              Возникли вопросы? Мы на связи!
            </Text>
            <ContactItem underlined>
              <PhoneIcon />
              <a href='tel:+79817950035'>+7 (981) 795-00-35</a>
            </ContactItem>
            <ContactItem>
              <EmailIcon />
              <a href='mailto:support@istock.info'>support@istock.info</a>
            </ContactItem>
          </div>
          <ContactsForm<T> {...formProps} />
        </ContactsWrapper>
      </div>
    </Suspense>
  );
}

export { ContactsSection as default };
