import React, { useCallback, useState, MouseEvent } from 'react';
import Carousel, {
  slidesToShowPlugin,
  autoplayPlugin,
  infinitePlugin,
} from '@brainhubeu/react-carousel';

import { Container } from '../../../../UI';
import { scrollToElement } from '../../utils';

import { SLIDES } from './slides';
import {
  MoreThen,
  ArrowPrevIcon,
  ArrowNextIcon,
  ImageWrapper,
  SliderWrapper,
  SlideText,
  Slide,
  SliderControls,
} from './styled';

const scrollToCatalogue = (a: MouseEvent<HTMLDivElement>) => {
  if (a.currentTarget.parentElement?.className.match('active')) {
    scrollToElement(a.currentTarget.dataset.id ?? '', false);
  }
};

const SLIDER_SETTINGS = {
  animationSpeed: 1000,
  draggable: false,
  plugins: [
    'centered',
    {
      resolve: autoplayPlugin,
      options: {
        interval: 4000,
      },
    },
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides: 3,
      },
    },
    {
      resolve: infinitePlugin,
      options: {
        numberOfInfiniteClones: 2,
      },
    },
  ],
  slides: SLIDES.map(({ text, img, id }) => (
    <Slide key={text} data-id={id} onClick={scrollToCatalogue}>
      <ImageWrapper>
        <img src={img} alt={text} />
      </ImageWrapper>
      <SlideText>{text}</SlideText>
    </Slide>
  )),
};

function SliderSection() {
  const [value, setValue] = useState(0);

  const onChange = useCallback((next: number) => {
    setValue(next);
  }, []);

  return (
    <SliderWrapper>
      <Container height='60px' textAlign='center'>
        <MoreThen>Более 15 каталогов</MoreThen>
      </Container>
      <SliderControls>
        <ArrowPrevIcon onClick={() => onChange(value - 1)} />
        <ArrowNextIcon onClick={() => onChange(value + 1)} />
      </SliderControls>
      <Carousel {...SLIDER_SETTINGS} value={value} onChange={onChange} />
    </SliderWrapper>
  );
}

export { SliderSection };
