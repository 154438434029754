import React, { ReactElement } from 'react';
import { Background, Parallax } from 'react-parallax';
import styled from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';

import parallax from '../../../public/images/nomenclature/parallax.png';
import { Notice } from '../../UI';
import { REGISTRATION_LINK } from '../../constants';
import { Grid } from '../../components/Grid';
import Footer from '../../components/Footer';

import { GallerySection } from './sections/Gallery';
import { CatalogueSection } from './sections/Catalogue';
import { SoonSection } from './sections/SoonSection';
import { NewToolsSection } from './sections/NewToolsSection';
import { ContactsSection } from './sections/Contacts';
import { DictionariesSection } from './sections/Dictionaries';
import { BannersSection } from './sections/Banners';
import { Header } from './components/Header';
import '../../components/sliderStyles.css';

const ParallaxImage = styled(Background)`
  height: 789px;
  width: 1743px;
  background: url('${parallax}') no-repeat;
`;

const GALLERY_MAIN_STYLE = { paddingTop: '161px', paddingBottom: 0 };
const CATALOGUE_MAIN_STYLE = {
  background: 'rgba(224, 235, 255, 0.2)',
  paddingTop: '8px',
  paddingBottom: '26px',
};
const DICTIONARIES_MAIN_STYLE = {
  background: 'rgba(224, 235, 255, 0.2)',
  paddingTop: '16px',
};

function App(): ReactElement {
  return (
    <Grid.Container>
      <Parallax strength={2500}>
        <ParallaxImage />
        <Header id='gtm_block_0' />
        <Grid.Main style={GALLERY_MAIN_STYLE}>
          <GallerySection />
        </Grid.Main>
        <Grid.Main style={CATALOGUE_MAIN_STYLE}>
          <Grid.FlexibleWrapper>
            <BannersSection />
            <CatalogueSection />
          </Grid.FlexibleWrapper>
        </Grid.Main>
        <Grid.FlexibleWrapper>
          <SoonSection />
        </Grid.FlexibleWrapper>
        <Grid.Main style={DICTIONARIES_MAIN_STYLE}>
          <Grid.FlexibleWrapper>
            <DictionariesSection />
          </Grid.FlexibleWrapper>
        </Grid.Main>
        <Grid.FlexibleWrapper>
          <NewToolsSection />
          <Notice
            mt={34}
            mb={100}
            title='Перейти в istock.info'
            link={REGISTRATION_LINK}
            mx={16}
            shadowStyle={{
              boxShadow:
                '0px 16px 28px -6px #4D7CFE4A, 0px 4px 11px -10px #4D7CFE33',
            }}
          >
            Узнать больше о системе
          </Notice>
          <ContactsSection id='gtm_block_7' />
        </Grid.FlexibleWrapper>
      </Parallax>
      <Footer />
    </Grid.Container>
  );
}

export { App };
