import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Text, Button, OutlineButton } from '../../../UI';
import logo from '../../../../public/images/nomenclature/istock.svg';
import { AUTH_LINK, REGISTRATION_LINK } from '../../../constants';
import { useWidth } from '../hooks';

const HeaderWrapper = styled.header<{ scroll: boolean; mobileHeight?: number }>(
  ({ theme: { colors, mediaQueries, breakpoints }, scroll }) => css`
    position: fixed;
    top: 0;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 0 52px;
    border-bottom: 1px solid transparent;
    transition: 0.2s ease-in-out background, 0.2s ease-in-out height,
      0.2s ease-in-out border-color, 0.4s ease-out transform;

    ${scroll &&
    css`
      background: ${colors.white};
      height: 62px;
      border-color: ${colors.divider};
    `};

    ${mediaQueries.s} {
      min-width: 336px;
      flex-direction: column;
      height: inherit;
      padding: 0 16px 16px;
    }

    // tablet
    @media screen and (min-width: ${breakpoints[1]}) and (max-width: ${breakpoints[2]}) {
      flex-direction: row;
      height: inherit;
      min-width: 336px;
      padding: 0 16px 0;
      background: ${colors.white};
      border-color: transparent;

      ${Button} {
        width: 100%;
      }

      ${scroll &&
      css`
        transform: translateY(-100%);
      `};
    }

    #header-info {
      ${mediaQueries.s} {
        margin-left: 24px;
        min-width: 149px;
        max-width: 149px;
        width: 149px;
      }

      // tablet
      @media screen and (min-width: ${breakpoints[1]}) and (max-width: ${breakpoints[2]}) {
        min-width: 100%;
        margin-left: 16px;
        font-size: 12px;
      }
    }
  `,
);

const LogoWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    align-items: center;
    margin-right: auto;

    img {
      display: block;
    }

    ${mediaQueries.m} {
      margin-right: 0;
      margin-top: 34px;
      margin-bottom: 28px;

      & img {
        max-width: none;
        width: 34px;
        height: 30.6px;
      }
    }
  `,
);

const SCROLLED_Y = 154;

function Header({ id }: { id: string }): JSX.Element {
  const [hasScroll, setScroll] = useState(false);
  const isPhone = useWidth();

  useEffect(() => {
    const isMobile = window.innerWidth <= 800;
    let scrollPosition = 0;

    const handleScroll = () => {
      const scrollTop = window.scrollY;

      requestAnimationFrame(() => {
        if (isMobile) {
          // not immediately, after scrolling the page
          if (scrollTop > SCROLLED_Y) {
            // if scroll is down that header should be hidden
            // if scroll is up that header should be shown
            setScroll(scrollTop > scrollPosition);
          }
          scrollPosition = scrollTop;
        } else {
          setScroll(scrollTop !== 0);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const Buttons = (
    <>
      <OutlineButton
        style={{ width: '154px' }}
        secondary
        as='a'
        target='_blank'
        href={AUTH_LINK}
        mr={16}
      >
        Войти
      </OutlineButton>
      <OutlineButton as='a' target='_blank' href={REGISTRATION_LINK}>
        Зарегистрироваться
      </OutlineButton>
    </>
  );

  const PhoneButtons = (
    <div style={{ display: 'flex', margin: '8px' }}>
      <OutlineButton
        secondary
        as='a'
        target='_blank'
        href={AUTH_LINK}
        px={28}
        mr={1}
      >
        Войти
      </OutlineButton>
      <OutlineButton
        style={{ color: '#3669fd', borderColor: '#3669fd' }}
        as='a'
        target='_blank'
        href={REGISTRATION_LINK}
        px={2}
      >
        Зарегистрироваться
      </OutlineButton>
    </div>
  );

  return (
    <HeaderWrapper scroll={hasScroll} id={id}>
      <LogoWrapper>
        <button
          type='button'
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        >
          <img src={logo} height={36} alt='istock.info' />
        </button>
        <Text
          fontSize={isPhone ? 12 : 18}
          fontWeight={600}
          ml={isPhone ? '4px' : 17}
        >
          Номенклатура.РФ
        </Text>
        <Text
          id='header-info'
          fontSize={[1, 2]}
          fontWeight={isPhone ? 500 : 600}
          ml={isPhone ? 24 : 17}
        >
          Вся информация о промышленности
        </Text>
      </LogoWrapper>
      {isPhone ? PhoneButtons : Buttons}
    </HeaderWrapper>
  );
}

export { Header };
