import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';
import { Container, Heading } from '../../../UI';
import { ReactComponent as NextImage } from '../../../../public/images/nomenclature/next.svg';
import { InfoButton } from '../../../UI/Button';
import { useWidth } from '../hooks';

const STYLE_HEADING = { alignSelf: 'flex-start' };
const STYLE_IMG = { marginLeft: 'auto', marginRight: '5px' };

const InsideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  background: #f5f6f8;
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  cursor: pointer;
`;

const CardIcon = styled.div<{ icon: string }>(
  ({ icon }) => css`
    min-width: 80px;
    min-height: 80px;
    background: url('${icon}');
    margin-right: 24px;
  `,
);

interface ICardProps {
  icon: string;
  heading: string;
  onClick: VoidFunction;
}

const LinkCard: FC<ICardProps> = memo(({ icon, heading, onClick }) => {
  const isPhone = useWidth();

  return (
    <InsideWrapper onClick={onClick}>
      <CardIcon icon={icon} />
      {isPhone ? (
        <Container
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          width='100%'
          height='80px'
        >
          <Heading
            as='h5'
            style={{ alignSelf: 'flex-start', fontSize: '16px' }}
          >
            {heading}
          </Heading>
          <InfoButton width='100%'>Подписаться</InfoButton>
        </Container>
      ) : (
        <>
          <Heading as='h5' style={STYLE_HEADING}>
            {heading}
          </Heading>
          <NextImage style={STYLE_IMG} />
        </>
      )}
    </InsideWrapper>
  );
});

export { LinkCard, InsideWrapper };
