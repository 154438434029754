import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import workerImage from '../../../../public/images/nomenclature/worker.svg';
import workerPhoneImage from '../../../../public/images/nomenclature/worker-mobile.svg';
import { Container, Heading, Text } from '../../../UI';

import { SliderSection } from '../components/Slider';
import { galleryCards } from '../cards';
import { useWidth } from '../hooks';

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1152px;
  justify-content: space-between;
  margin: 0 auto;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 24px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 28px;

  height: 94px;

  background: linear-gradient(
    277.18deg,
    #ffffff 80.75%,
    rgba(255, 255, 255, 0) 103.86%
  );
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 1;

  &:first-child {
    background: linear-gradient(
      78.23deg,
      #ffffff 68.47%,
      rgba(255, 255, 255, 0) 92.8%
    );
  }
  &:last-child {
    background: #ffffff;
  }
`;

const CardIcon = styled.div<{ icon: string }>(
  ({ icon }) => css`
    min-width: 46px;
    min-height: 46px;
    width: 46px;
    height: 46px;
    background: url('${icon}') no-repeat center;
    border-radius: 50%;
    box-shadow: 0px 14px 24px 0px #3e43680d;
  `,
);

const WorkerWrapper = styled.div`
  min-width: 445px;
  min-height: 380px;
  position: absolute;
  left: 35.5%;
  right: 39.5%;
  top: 6.82%;
  bottom: 83.1%;
  z-index: -2;
  background: url('${workerImage}') no-repeat center;
`;

const PhoneWorkerWrapper = styled.div(
  ({ theme: { mediaQueries, breakpoints } }) => css`
    background: url('${workerPhoneImage}') no-repeat center;
    background-size: contain;
    width: 100%;
    min-height: 162px;
    height: 100%;
    margin-bottom: 16px;
    padding: 0 16px;

    ${mediaQueries.s} {
      margin-top: 0;
      margin-bottom: 17px;
    }

    // tablet
    @media screen and (min-width: ${breakpoints[1]}) and (max-width: ${breakpoints[2]}) {
      margin-top: -89px;
      margin-bottom: 28px;
    }
  `,
);

const PhoneCardWrapper = styled.div(
  ({ theme: { mediaQueries, breakpoints } }) => css`
    margin-top: 60px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
    gap: 7px;

    ${mediaQueries.s} {
      margin-top: 52px;
    }

    // tablet
    @media screen and (min-width: ${breakpoints[1]}) and (max-width: ${breakpoints[2]}) {
      margin-top: 27px;
      display: grid;
      grid-template-columns: 2fr 50%;
      gap: 15px;
    }
  `,
);

const TitleWrapper = styled(Container)(
  ({ theme: { mediaQueries } }) => css`
    width: 642px;
    padding-top: 95px;

    ${mediaQueries} {
      padding-top: 30px;
    }
  `,
);

const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

function PhoneCard({
  text,
  icon,
}: {
  text: string;
  icon: string;
}): ReactElement {
  return (
    <Container display='flex' gap='10px' padding='8px'>
      <CardIcon icon={icon} />
      <div style={{ minWidth: '256px' }}>
        {text.split('\n').map((string, index) => {
          return (
            <div key={index} style={{ fontSize: '14px', lineHeight: '22.4px' }}>
              {string}
            </div>
          );
        })}
      </div>
    </Container>
  );
}

function Card({ text, icon }: { text: string; icon: string }): ReactElement {
  return (
    <CardInner>
      <CardIcon icon={icon} />
      <div style={{ minWidth: '306px' }}>
        {text.split('\n').map((string, index) => {
          return (
            <div key={index} style={{ fontSize: '16px' }}>
              {string}
            </div>
          );
        })}
      </div>
    </CardInner>
  );
}

const PhoneGallerySection: ReactElement = (
  <div style={{ marginTop: '28px' }}>
    <PhoneWorkerWrapper />
    <div>
      <Heading style={{ fontSize: '20px' }}>Номенклатура.РФ</Heading>
      <Text fontSize={3} mt={2} mb={1} color='text.secondary'>
        Справочная информация
        {window.innerWidth < 800 && <br />} о продукции промышленного сектора
        России
      </Text>
    </div>
    <PhoneCardWrapper>
      {galleryCards.map(({ description, icon, id }) => (
        <PhoneCard text={description} icon={icon} key={id} />
      ))}
    </PhoneCardWrapper>
  </div>
);

const DesktopGallerySection: ReactElement = (
  <GalleryWrapper>
    <Container
      display='flex'
      flexDirection='row'
      height='488px'
      width='1153px'
      justifyContent='space-between'
    >
      <TitleWrapper>
        <Heading>Номенклатура.РФ</Heading>
        <p style={{ fontSize: '28px' }}>
          Справочная информация о продукции промышленного сектора России
        </p>
      </TitleWrapper>
      <SliderSection />
    </Container>
    <WorkerWrapper />
    <CardsWrapper>
      {galleryCards.map(({ description, icon, id }) => (
        <CardWrapper key={id}>
          <Card text={description} icon={icon} />
        </CardWrapper>
      ))}
    </CardsWrapper>
  </GalleryWrapper>
);

function GallerySection(): ReactElement {
  const isPhone = useWidth();
  return isPhone ? PhoneGallerySection : DesktopGallerySection;
}

export { GallerySection };
