import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import modalBg from '../../../../public/images/nomenclature/modal_large.png';

import {
  TextInput,
  IContactFormProps,
  ContactsForm,
  Form,
  IFormFields,
} from '../../../components/Contacts';
import { MESSAGE_TITLE, SECTION } from '../../../constants';
import { Modal, ModalWindow } from '../../../components/Modal';
import { CONTACTS_API_ENDPOINT } from '../constants';
import { makeSendMessage } from '../utils';

const FORM_TEXT = (
  <>
    Возникли вопросы или нужна консультация? <br />
    Оставьте свои контакты — перезвоним в ближайшее время.
  </>
);

const ModalWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    ${ModalWindow} {
      padding-top: 214px;
      background: #fff url(${modalBg}) no-repeat center 40px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      ${mediaQueries.m} {
        padding: 16px;
        padding-top: 164px;

        min-width: 328px;
        width: 90vw;
        left: 50%;
        margin-left: -45vw;
        transform: translateY(-50%);
      }
    }
  `,
);

const ModalText = styled.div`
  font-size: 16px;
  margin-bottom: 20px;

  & span {
    font-size: 18px;
    font-weight: bold;
  }
`;

const FormWrapper = styled.div`
  ${Form} {
    padding: 0;
    background: none;
  }
`;

const FORM_FIELDS: IFormFields[] = ['name', 'email'];

type IAdditionalData = {
  companyName: string;
};

const additionalFieldsRender: IContactFormProps<IAdditionalData>['additionalFieldsRender'] =
  ({ setFormData, formData }) => {
    return (
      <TextInput
        title='Название компании'
        placeholder=''
        value={formData.companyName}
        onChange={event =>
          setFormData(prev => ({ ...prev, companyName: event.target.value }))
        }
      />
    );
  };

const useModal = () => {
  const [isVisibleModal, setVisibleModal] = useState(false);
  const switchModal = useCallback(() => setVisibleModal(state => !state), []);
  return useMemo(
    () => ({
      isVisibleModal,
      switchModal,
    }),
    [isVisibleModal, switchModal],
  );
};

const ModalForm: FC<{
  isVisible: boolean;
  onClose: VoidFunction;
  text: JSX.Element | string;
}> = memo(({ text, onClose, isVisible }) => {
  return (
    <ModalWrapper>
      <Modal isVisible={isVisible} onClose={onClose}>
        <ModalText>
          <>
            Каталог <span>{text}</span> скоро будет добавлен в систему. Оставьте
            свои контактные данные, чтобы получить рассылку, когда это случится.
          </>
        </ModalText>
        <FormWrapper>
          <ContactsForm<IAdditionalData>
            catalogueName={text}
            section={SECTION.FOR_NOMENCLATURE}
            title={MESSAGE_TITLE.FOR_NOMENCLATURE}
            formText={FORM_TEXT}
            formFIOText='ФИО'
            showRequiredSign
            showFields={FORM_FIELDS}
            showRequiredText={false}
            showWelcomeText={false}
            endpoint={CONTACTS_API_ENDPOINT}
            additionalFieldsRender={additionalFieldsRender}
            getMessageText={makeSendMessage}
            handleSuccess={onClose}
          />
        </FormWrapper>
      </Modal>
    </ModalWrapper>
  );
});

export { useModal, ModalForm, FORM_TEXT };
