import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.footer(
  ({ theme: { mediaQueries } }) => css`
    padding: 34px 52px;

    a {
      color: inherit;
    }

    ${mediaQueries.m} {
      padding-left: 16px;
      padding-right: 16px;
    }

    ${mediaQueries.s} {
      padding-top: 21px;
      padding-bottom: 21px;
      height: 64px;
    }
  `,
);

const CURRENT_YEAR: number = new Date().getFullYear();

function Footer() {
  return (
    <Container>
      © {CURRENT_YEAR}{' '}
      <a href='https://istock.info/' target='_blank' rel='noreferrer'>
        istock.info
      </a>
    </Container>
  );
}

export { Footer as default };
