import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import { Container, Heading, IconButton } from '../../../UI';
import { LinkCard } from '../components/LinkCard';

import { newToolsCards } from '../cards';
import { ModalForm, useModal } from '../components/Modal';
import { useWidth } from '../hooks';
import { SliderControls } from '../components/Slider/styled';
import { ReactComponent as ArrowNextIcon } from '../../../../public/icons/arrow-next.svg';
import { ReactComponent as ArrowPrevIcon } from '../../../../public/icons/arrow-prev.svg';

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;

const SlideCounter = styled.div(
  ({ theme: { colors } }) => css`
    margin: 0 16px;
    text-align: center;
    color: ${colors.text.disabled};
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 20px;
  `,
);

function NewToolsSection(): ReactElement {
  const [modalItemName, setModalItemName] = useState('');
  const { isVisibleModal, switchModal } = useModal();
  const isPhone = useWidth();

  const handleShowModal = useCallback(
    (itemName: string) => {
      setModalItemName(itemName);
      switchModal();
    },
    [switchModal],
  );

  const [currentSlide, setCurrentSlide] = useState(1);
  const sliderRef = useRef<any>();

  const settings = useMemo(
    () => ({
      dots: false,
      slideWidth: 0,
      arrows: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      beforeChange: (_currentSlide: number, nextSlide: number) => {
        setCurrentSlide(nextSlide + 1);
      },
    }),
    [],
  );

  return (
    <Container mt='46px' display='block' px={16}>
      <Heading as='h4' fontSize='20px' mb={22}>
        Новые инструменты
      </Heading>
      {isPhone ? (
        <>
          <Slider {...settings} ref={sliderRef}>
            {newToolsCards.map(({ icon, heading }) => (
              <LinkCard
                key={heading}
                icon={icon}
                heading={heading}
                onClick={() => handleShowModal(heading)}
              />
            ))}
          </Slider>
          <SliderControls>
            <IconButton onClick={() => sliderRef.current.slickPrev()}>
              <ArrowPrevIcon />
            </IconButton>
            <SlideCounter>
              0{currentSlide} - 0{newToolsCards.length}
            </SlideCounter>
            <IconButton onClick={() => sliderRef.current.slickNext()}>
              <ArrowNextIcon />
            </IconButton>
          </SliderControls>
        </>
      ) : (
        <CardsWrapper>
          {newToolsCards.map(({ icon, heading }) => (
            <LinkCard
              key={heading}
              icon={icon}
              heading={heading}
              onClick={() => handleShowModal(heading)}
            />
          ))}
        </CardsWrapper>
      )}
      <ModalForm
        text={modalItemName}
        onClose={switchModal}
        isVisible={isVisibleModal}
      />
    </Container>
  );
}

export { NewToolsSection };
