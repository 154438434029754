import Slider from 'react-slick';
import React, { MutableRefObject, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { noop } from 'lodash';
import { SpaceProps } from 'styled-system';
import { Container, Heading, IconButton } from '../../../UI';
import { DictionaryCard } from './DictionaryCard';
import { SliderControls } from './Slider/styled';

import { ReactComponent as ArrowNextIcon } from '../../../../public/icons/arrow-next.svg';
import { ReactComponent as ArrowPrevIcon } from '../../../../public/icons/arrow-prev.svg';
import { ICardsProps } from '../types';

const SlideCounter = styled.div(
  ({ theme: { colors } }) => css`
    margin: 0 16px;
    text-align: center;
    color: ${colors.text.disabled};
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 20px;
  `,
);

interface IDictionaryCardSliderProps {
  heading?: string;
  cards: ICardsProps[];
  sliderRef: MutableRefObject<any>;
  onShowModal?: (itemName: string) => void;
}

const DictionaryCardSlider = ({
  heading = '',
  cards,
  sliderRef,
  onShowModal = noop,
  ...other
}: IDictionaryCardSliderProps & SpaceProps) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const settings = useMemo(
    () => ({
      dots: false,
      slideWidth: 0,
      arrows: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      beforeChange: (_currentSlide: number, nextSlide: number) => {
        setCurrentSlide(nextSlide + 1);
      },
    }),
    [],
  );

  return (
    <Container mb={68} {...other}>
      {heading && (
        <Heading mb={2} as='h4'>
          {heading}
        </Heading>
      )}
      <Slider {...settings} ref={sliderRef}>
        {cards.map((props, index) => (
          <DictionaryCard
            onShowModal={onShowModal}
            isPhone
            {...props}
            key={props.id}
            index={index}
          />
        ))}
      </Slider>
      <SliderControls>
        <IconButton onClick={() => sliderRef.current.slickPrev()}>
          <ArrowPrevIcon />
        </IconButton>
        <SlideCounter>
          0{currentSlide} - 0{cards.length}
        </SlideCounter>
        <IconButton onClick={() => sliderRef.current.slickNext()}>
          <ArrowNextIcon />
        </IconButton>
      </SliderControls>
    </Container>
  );
};

export { DictionaryCardSlider };
