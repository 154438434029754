import React, { FC, memo } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../public/icons/close.svg';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    overflow: hidden;
  }
`;

const bgAnimation = keyframes`
 0% { background: rgba(0, 0, 0, 0); }
 100% { background: rgba(0, 0, 0, 0.7); }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  animation: ${bgAnimation} 0.6s forwards;
  animation-iteration-count: 1;
  z-index: 500;

  &:target {
    background: rgba(0, 0, 0, 0.7);
  }
`;

export const ModalWindow = styled.div`
  position: fixed;
  top: 50%;
  transform: translate(25%, -50%);
  padding: 46px;
  width: 800px;
  border-radius: 8px;
  background-color: #fff;
  z-index: 600;

  p {
    font-size: 18px;
    font-weight: 400;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;

  > svg {
    fill: #494f54;
  }
`;

type IModalProps = {
  isVisible: boolean;
  onClose: VoidFunction;
  children: JSX.Element | JSX.Element[];
};

export const Modal: FC<IModalProps> = memo(
  ({ isVisible, onClose, children }) => {
    return !isVisible ? null : (
      <>
        <GlobalStyle />
        <Backdrop onClick={onClose} />
        <ModalWindow>
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
          {children}
        </ModalWindow>
      </>
    );
  },
);
