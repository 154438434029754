import React, { useState, Fragment } from 'react';
import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

import { ButtonWithShadow, Text } from '../../UI';
import { FormData, IContactFormProps } from './types';
import { createIssues } from './api';

export const Form = styled.form(
  ({ theme: { colors, mediaQueries } }) => css`
    padding: 46px;
    margin-top: -30px;
    background: ${colors.background};
    border-radius: 16px;

    ${mediaQueries.s} {
      margin-top: 30px;
      padding: 28px 16px;
    }
  `,
);

const Input = styled.input(
  ({ theme: { colors, mediaQueries, fontSizes } }) => css`
    width: 100%;
    height: 46px;
    line-height: 46px;
    padding: 0 16px;
    margin-bottom: 28px;
    background: transparent;
    border-bottom: 1px solid ${colors.divider};
    transition: 0.2s ease-in-out border-color;
    font-size: ${fontSizes[3]}px;
    font-family: inherit;

    &:focus {
      border-color: ${colors.text.primary};
    }

    ${mediaQueries.s} {
      margin-bottom: 16px;
    }
  `,
);

const FormFooter = styled.div(
  ({ theme: { fontSizes, colors, mediaQueries } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 29px;
    font-size: ${fontSizes[1]}px;
    color: ${colors.text.secondary};

    ${mediaQueries.s} {
      margin-top: 0;
      display: block;
      text-align: center;

      ${ButtonWithShadow} {
        margin-top: 8px;
        width: 100%;
      }
    }
  `,
);

const Label = styled.label(
  ({ theme: { colors } }) => css`
    position: absolute;
    left: 16px;
    top: 10px;
    z-index: 0;
    color: ${colors.text.secondary};
    pointer-events: none;
    transition: 0.2s ease-in-out top, 0.2s ease-in-out font-size;

    > span {
      color: #f63c23;
    }
  `,
);

const InputWrapper = styled.div<{ hasValue?: boolean }>(
  ({ theme: { colors, fontSizes }, hasValue }) => css`
    position: relative;
    font-size: ${fontSizes[3]}px;

    & ${Input}, & input {
      &::placeholder {
        color: transparent;
      }

      &:focus::placeholder {
        color: ${colors.text.secondary};
      }
    }

    &:focus-within ${Label} {
      top: -1px;
      font-size: ${fontSizes[0]}px;
    }

    ${hasValue &&
    css`
      & ${Label} {
        top: -1px;
        font-size: ${fontSizes[0]}px;
      }
    `}
  `,
);

type InputProps = Pick<IContactFormProps, 'title' | 'showRequiredSign'> & {
  placeholder: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function TextInput({
  title,
  placeholder,
  value,
  onChange,
  showRequiredSign,
}: InputProps) {
  return (
    <InputWrapper hasValue={Boolean(value)}>
      <Label>
        {title}
        {showRequiredSign && <span> *</span>}
      </Label>
      <Input placeholder={placeholder} value={value} onChange={onChange} />
    </InputWrapper>
  );
}

const DEFAULT_MESSAGE = 'Все поля обязательны для заполнения';

const initialFormData = {
  name: '',
  phone: '',
  formattedPhone: '',
  question: '',
} as FormData;

const PHONE_LENGTH = 10;

const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function ContactsForm<T = {}>({
  section,
  title,
  formText,
  formFIOText = 'Имя',
  showFields = ['name', 'phone'],
  showRequiredText = true,
  showRequiredSign = false,
  showWelcomeText = true,
  additionalFieldsRender,
  endpoint,
  getMessageText,
  handleSuccess = () => {},
  catalogueName = '',
}: IContactFormProps<T>): JSX.Element {
  const [formData, setFormData] = useState<FormData<T>>(
    initialFormData as FormData<T>,
  );
  const [message, setMessage] = useState<string | JSX.Element>(
    showRequiredText ? DEFAULT_MESSAGE : ' ',
  );
  const [isSubmitting, setSubmitting] = useState(false);

  const disabled =
    isSubmitting ||
    !(
      formData.name &&
      (formData.phone.length === PHONE_LENGTH ||
        formData.email?.match(EMAIL_REGEXP))
    );

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setSubmitting(true);

    createIssues({
      formData,
      section,
      title,
      endpoint,
      message: getMessageText?.({ ...formData, catalogueName }) ?? undefined,
    }).then(
      () => {
        setMessage(<mark>Сообщение отправлено.</mark>);
        setSubmitting(false);
        setFormData(initialFormData as FormData<T>);

        setTimeout(() => setMessage(DEFAULT_MESSAGE), 5000);
        handleSuccess();
      },
      () => {
        setMessage('Произошла ошибка. Пожалуйста обновите страницу.');
        setSubmitting(false);
      },
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      {showWelcomeText && (
        <Text mb={[16, 16, 28]}>
          {formText ?? (
            <>
              Остались вопросы или нужна консультация? <br />
              Оставьте свой номер телефона — мы перезвоним вам в ближайшее
              время.
            </>
          )}
        </Text>
      )}
      {showFields.includes('name') && (
        <TextInput
          title={formFIOText}
          placeholder=''
          value={formData.name}
          showRequiredSign={showRequiredSign}
          onChange={event =>
            setFormData(prev => ({ ...prev, name: event.target.value }))
          }
        />
      )}
      {showFields.includes('email') && (
        <TextInput
          title='Email'
          placeholder=''
          showRequiredSign={showRequiredSign}
          value={formData.email ?? ''}
          onChange={event =>
            setFormData(prev => ({ ...prev, email: event.target.value }))
          }
        />
      )}
      {showFields.includes('phone') && (
        <NumberFormat
          title='Телефон'
          format='+7 (###) ###-##-##'
          showRequiredSign={showRequiredSign}
          placeholder='+7 (ХХХ) ХХХ-ХХ-ХХ'
          mask='_'
          value={formData.phone}
          customInput={TextInput}
          onValueChange={({ formattedValue, value }) => {
            setFormData(prev => ({
              ...prev,
              phone: value,
              formattedPhone: formattedValue,
            }));
          }}
        />
      )}
      {additionalFieldsRender?.({ setFormData, formData }) ?? null}
      <FormFooter>
        <span>{message}</span>
        <ButtonWithShadow disabled={disabled} type='submit' size='m'>
          {showFields.includes('email') ? 'Оставить заявку' : 'Перезвоните'}
        </ButtonWithShadow>
      </FormFooter>
    </Form>
  );
}

export { ContactsForm };
