import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { Heading } from '../../../UI';
import Contacts, {
  IContactFormProps,
  TextInput,
} from '../../../components/Contacts';
import { MESSAGE_TITLE, SECTION } from '../../../constants';
import { FORM_TEXT } from '../components/Modal';
import { CONTACT_SECTION_ID, CONTACTS_API_ENDPOINT } from '../constants';
import { makeSendMessage } from '../utils';

const ContactsWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    p {
      font-size: 16px;
    }

    ${Heading} {
      font-size: 24px;
    }

    ${mediaQueries.m} {
      padding: 0 16px;

      p {
        font-size: 14px;
      }
      ${Heading} {
        font-size: 20px;
        margin-bottom: 26px;
      }
    }
  `,
);

type IAdditionalData = {
  question: string;
};

const additionalFieldsRender: IContactFormProps<IAdditionalData>['additionalFieldsRender'] =
  ({ setFormData, formData }) => {
    return (
      <TextInput
        title='Ваш вопрос'
        placeholder=''
        value={formData.question}
        onChange={event =>
          setFormData(prev => ({ ...prev, question: event.target.value }))
        }
      />
    );
  };

const ContactsSection: FC<ISection> = ({ id }) => {
  return (
    <ContactsWrapper id={CONTACT_SECTION_ID}>
      <Contacts<IAdditionalData>
        section={SECTION.FOR_NOMENCLATURE}
        title={MESSAGE_TITLE.FOR_NOMENCLATURE}
        id={id}
        formText={FORM_TEXT}
        showRequiredSign
        showRequiredText={false}
        endpoint={CONTACTS_API_ENDPOINT}
        additionalFieldsRender={additionalFieldsRender}
        getMessageText={makeSendMessage}
      />
    </ContactsWrapper>
  );
};

export { ContactsSection };
