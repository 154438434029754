import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import Slider from 'react-slick';
import { Container, Heading, IconButton } from '../../../UI';
import soonImage from '../../../../public/images/nomenclature/soon.png';
import KSRImage from '../../../../public/images/nomenclature/KSR.svg';
import KTRUImage from '../../../../public/images/nomenclature/KTRU.svg';
import foodImage from '../../../../public/images/nomenclature/food.svg';
import rawImage from '../../../../public/images/nomenclature/raw.svg';
import buildImage from '../../../../public/images/nomenclature/build.svg';
import ntdImage from '../../../../public/images/nomenclature/NTD.svg';
import subscribeImage from '../../../../public/images/nomenclature/subscribe.png';
import phoneSubscribeImage from '../../../../public/images/nomenclature/subscribeandlearn_mobile.svg';
import planeImage from '../../../../public/images/nomenclature/plane.svg';

import { ReactComponent as ArrowNextIcon } from '../../../../public/icons/arrow-next.svg';
import { ReactComponent as ArrowPrevIcon } from '../../../../public/icons/arrow-prev.svg';

import { InsideWrapper, LinkCard } from '../components/LinkCard';
import { ModalForm, useModal } from '../components/Modal';
import { CONTACT_SECTION_ID } from '../constants';
import { scrollToElement } from '../utils';
import { useWidth } from '../hooks';
import { SliderControls } from '../components/Slider/styled';

const cards = [
  { icon: KSRImage as string, heading: 'КСР', disabled: true },
  { icon: KTRUImage as string, heading: 'КТРУ', disabled: true },
  {
    icon: foodImage as string,
    heading: 'Пищевая продукция',
    disabled: true,
  },
  { icon: rawImage as string, heading: 'Сырье', disabled: true },
  {
    icon: buildImage as string,
    heading: 'Строительные материалы',
    disabled: true,
  },
  {
    icon: ntdImage as string,
    heading: 'НТД на технические условия',
    disabled: true,
  },
];

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 112px);
  gap: 24px;
  margin-top: 28px;
`;

const SubscribeAndLearn = styled.div(
  ({ theme: { mediaQueries } }) => css`
    background: url(${soonImage}) no-repeat center;

    ${mediaQueries.m} {
      background: url(${phoneSubscribeImage}) no-repeat center;
      height: 112px;
      margin: 16px 0 37px;
    }
  `,
);

const PlaneWrapper = styled.div`
  min-width: 310px;
  min-height: 105px;
  position: relative;
  top: -83px;
  left: 113px;
  z-index: -2;
  background: url('${subscribeImage}') no-repeat center;
`;

const NotFoundInfo = styled(InsideWrapper)`
  background: #ffffff00;
  grid-row-end: 4;
  grid-column-start: 2;
  padding: 29px 16px;
  display: block;
  cursor: default;
`;

const PhoneNotFoundInfo = styled(InsideWrapper)`
  background: #b8cdff40;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  cursor: default;

  font-size: 14px;
  font-weight: 400;
`;

const Plane = styled.div`
  background: url(${planeImage}) no-repeat center;
  min-width: 40px;
  height: 30px;
`;

const NotFoundInfoLink = styled.span`
  color: #3669fd;
  cursor: pointer;
`;

const SlideCounter = styled.div(
  ({ theme: { colors } }) => css`
    margin: 0 16px;
    text-align: center;
    color: ${colors.text.disabled};
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 20px;
  `,
);

const scrollToContacts = () => {
  scrollToElement(CONTACT_SECTION_ID);
};

function SoonSection(): ReactElement {
  const [modalItemName, setModalItemName] = useState('');
  const { isVisibleModal, switchModal } = useModal();
  const isPhone = useWidth();

  const handleShowModal = useCallback(
    (itemName: string) => {
      setModalItemName(itemName);
      switchModal();
    },
    [switchModal],
  );

  const [currentSlide, setCurrentSlide] = useState(1);
  const sliderRef = useRef<any>();

  const settings = useMemo(
    () => ({
      dots: false,
      slideWidth: 0,
      arrows: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      beforeChange: (_currentSlide: number, nextSlide: number) => {
        setCurrentSlide(nextSlide + 1);
      },
    }),
    [],
  );

  return (
    <Container mt={isPhone ? 50 : 39} px={16} mb={isPhone ? 40 : -7}>
      <Heading as='h4' style={{ fontSize: '20px' }}>
        Скоро в системе
      </Heading>
      {isPhone ? (
        <div>
          <SubscribeAndLearn />
          <div style={{ marginBottom: '34px' }}>
            <Slider {...settings} ref={sliderRef}>
              {cards.map(({ icon, heading }) => (
                <LinkCard
                  key={heading}
                  icon={icon}
                  heading={heading}
                  onClick={() => handleShowModal(heading)}
                />
              ))}
            </Slider>
            <SliderControls>
              <IconButton onClick={() => sliderRef.current.slickPrev()}>
                <ArrowPrevIcon />
              </IconButton>
              <SlideCounter>
                0{currentSlide} - 0{cards.length}
              </SlideCounter>
              <IconButton onClick={() => sliderRef.current.slickNext()}>
                <ArrowNextIcon />
              </IconButton>
            </SliderControls>
          </div>
          <PhoneNotFoundInfo>
            <div>
              Не нашли нужную информацию?{' '}
              <NotFoundInfoLink onClick={scrollToContacts}>
                Оставьте заявку
              </NotFoundInfoLink>
            </div>
            <Plane />
          </PhoneNotFoundInfo>
        </div>
      ) : (
        <>
          <CardsWrapper>
            <SubscribeAndLearn />
            {cards.map(({ icon, heading }) => (
              <LinkCard
                key={heading}
                icon={icon}
                heading={heading}
                onClick={() => handleShowModal(heading)}
              />
            ))}
            <NotFoundInfo>
              Не нашли нужную информацию?
              <NotFoundInfoLink onClick={scrollToContacts}>
                Оставьте заявку
              </NotFoundInfoLink>
            </NotFoundInfo>
          </CardsWrapper>
          <PlaneWrapper />
        </>
      )}
      <ModalForm
        text={modalItemName}
        onClose={switchModal}
        isVisible={isVisibleModal}
      />
    </Container>
  );
}

export { SoonSection };
